import{ Menu, Welcome, Advantages, NFTgames, Slider, LiableSwap, TokenInfo, Tokenomics, Roadmap, Partners, Technology, WalletSupport, Footer }from './components'
import './index.css'
import './App.css'
import left from "./assets/img/left.webm"
import right from "./assets/img/right.webm"
import { useState } from 'react'
import addRecord from './subscription'

export default function Main() {

  const [ email, setEmail ] = useState()
  const [ buttonText, setButtonText ] = useState("Get news")
  const sendSub = () => {
    addRecord(email)
    .then( (res) => {
      setEmail("")
      setButtonText("Submited!")

      setInterval(() => {
        setButtonText("Get news")
      }, 5000);
    })
  }
  return (
    <main className='w-full bg-black '>

      <Menu />
      <Welcome />
      <Advantages />
      <NFTgames />
      <Slider />
      <LiableSwap />
      <section className='flex flex-row h-screen'>
        <div className='absolute w-full flex flex-col md:flex-row'>
          <video className='flex w-1/2 xl:w-auto md:w-1/3 h-fit md:h-screen mr-auto' src={left} muted autoPlay loop/>
          <video className='flex w-1/2 xl:w-auto md:w-1/3 h-fit md:h-screen ml-auto mt-[280px]' src={right} muted autoPlay loop/>
        </div>
        <div className='flex flex-col md:max-w-xl lg:max-w-3xl mx-auto text-white my-auto'>
          <span className='px-4 py-2 bg-[#05e1bc29] text-[#05e1bd] rounded-full w-fit mx-auto mb-4 font-medium'>1 GNG = $0.000765</span>
          <h2 className='text-7xl lg:text-8xl font-black text-center'>Play2Earn With <br></br><span className='gamengain-color'>GNG</span></h2>
          <p className='text-[#cecece] text-center mt-6'>GNG is the access & governance token behind the whole gaming platform. Combined with partner tokens, it provides a sustainable economics model for the Game'nGain ecosystem.</p>
          {/* <button className="mx-auto mt-6 buyGNG-btn w-[108px] h-[40px] text-white text-[14px] font-bold md:mb-[90px] xl:mb-6 ">About GNG</button> */}
        </div>
      </section>
      <TokenInfo />
      <Tokenomics />
      <Roadmap />
      <Partners />
      <Technology />
      <section className='subscribe mt-32 text-white flex flex-col md:flex-row md:px-28 lg:px-60 xl:px-72 2xl:px-96 py-16'>
        <div>
          <h3 className='text-5xl font-bold text-center md:text-left'>
            Be the first<br></br>to know
          </h3>
        </div>
        <div className='ml-auto flex flex-col pr-0 xl:pr-20'>
          <p className='mx-auto md:ml-auto md:mr-0 text-xl w-10/12 text-center md:text-left md:w-2/4 lg:w-5/12  mt-4 md:mt-0'>Sign up for updates. No spam, just exciting stuff from the world of blockchain gaming.</p>
          <div className='mx-auto md:ml-auto md:mr-0  mt-4'>
            <input className='w-[250px] py-2 px-4 rounded-full bg-white/30 text-black  placeholder:text-black/60 outline-none' placeholder='Your email' type='email' value={email} onChange={(e) => {setEmail(e.target.value)}}/>
            <button className='bg-white text-black rounded-full h-full px-4 py-2 -ml-8' onClick={sendSub}>{buttonText}</button>
          </div>
        </div>
      </section>
      <WalletSupport />
      <Footer />
    </main>
  )
}