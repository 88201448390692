import techno from '../assets/img/techno.webm'

const Technology = () => {
    return (
        <div className="w-full bg-[black] flex justify-center">
            <div className="pattern w-[80%] bg-[sky] mt-[105px] md:mt-[320px] flex flex-wrap text-white">
                <section className="w-[100%] md:w-[50%] flex flex-wrap items-center">
                    <div >
                    <h2 className="text-[32px] md:text-[56px] font-bold"><span className="gamengain-color text-[32px] md:text-[56px] font-bold">The technology</span> that powers...</h2>
                    <p className="w-[68%] text-[#93969C] font-medium pt-[16px] ">Polygon is a “layer two” or “sidechain” scaling solution that runs alongside the Ethereum blockchain — allowing for speedy transactions and low fees. Thanks to Polygon our token is guaranteed by an ultimate technology.</p>
                    </div>
                </section>
                <section className="w-[100%] md:w-[50%] flex justify-center relative pt-[32px] md:pt-0 ">
                    <video className='z-50' autoPlay loop muted src={techno}  />
                    <div className='tenthSecshadow1 w-[50px] md:w-[111px]  rounded-[254px] absolute top-[40%] left-[15%] rotate-[-35deg]'></div>
                    <div className='tenthSecshadow2 w-[50px] md:w-[111px]  rounded-[254px] absolute top-[60%] right-[15%] rotate-[-35deg]'></div>
                </section>
            </div>
        </div>
    )
}


export default Technology