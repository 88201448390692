import React from "react";
import Slider from "react-slick";
import wallet1 from '../assets/img/wallet1.png'
import wallet2 from '../assets/img/wallet2.png'
import wallet3 from '../assets/img/wallet3.png'
import wallet4 from '../assets/img/wallet4.png'
import wallet5 from '../assets/img/wallet5.png'

export default function WalletsSupport() {
  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1
        }
      }
    ]
  };
  return (
    <>
     <div className="overflow-hidden cursor-pointer bg-[black] pt-[64px] md:pt-[227px] text-white flex justify-center ">
      <section className="w-[80%] relative">
     <h2 className="font-bold text-[32px] md:text-[56px] text-white text-center pb-[90px]">Wallets we supports</h2>

     <div className="eleventhSecshadow1 w-[615px]  absolute top-[55%] left-[-5%] hidden md:flex"></div>
     <div className="eleventhSecshadow2 w-[615px]  absolute top-[55%] right-[-5%] hidden md:flex"></div>
    <Slider {...settings}>
      <div className=" outline-0">
        <img src={wallet1} alt="wallet 1"/>
      </div>
      <div className=" outline-0">
        <img src={wallet2} alt="wallet 2"/>
      </div>
      <div className=" outline-0">
        <img src={wallet3} alt="wallet 3"/>
      </div>
      <div className=" outline-0">
        <img src={wallet4} alt="wallet 4"/>
      </div>
      <div className=" outline-0">
        <img src={wallet5} alt="wallet 5"/>
      </div>
    </Slider>
     </section>
     </div>

    
    </>
  );
}