

const TokenInfo = () => {
    return (
        <div className="bg-[black] flex flex-wrap justify-center pt-[64px] md:pt-[128px]">
            <h3 className="w-full text-[32px] md:text-[56px] font-bold text-center text-white">Token Information</h3>
            <div className="tokenInfoborder w-[80%] lg:w-[70%] xl:w-[65%] 2xl:w-[60%] mt-[32px] md:mt-[80px] md:h-[168px] flex flex-wrap relative ">
                <div className="hidden md:flex sixthSecshadow1 rounded-[254px] absolute top-0 lg:right-[-5%]"></div>
                <section className="w-[100%] md:w-[50%] flex flex-wrap items-center">
                    <div className="w-[100%]">
                        <div className="w-[55%]  ml-[40px] flex flex-wrap mt-[32px] md:mt-0">
                            <section className="w-[100%] md:w-[50%] ">
                                <p className="font-black text-[18px] text-[#93969C]">Symbol</p>
                            </section>
                            <section className="w-[100%] md:w-[50%]  mt-[20px] md:mt-0">
                                <p className="font-bold text-[18px] text-white md:text-end">GNG</p>
                            </section>
                        </div>
                        <div className="w-[60%] lg:w-[55%] ml-[40px] flex mt-[24px] flex-wrap">
                            <section className="w-[100%] md:w-[50%] ">
                                <p className="font-black text-[18px] text-[#93969C]">Network</p>
                            </section>
                            <section className="w-[100%] md:w-[50%]  mt-[20px] md:mt-0">
                                <p className="font-bold text-[18px] text-white md:text-end">Polygon</p>
                            </section>
                        </div>
                        <div className="w-[60%] lg:w-[55%] ml-[40px] flex mt-[24px] flex-wrap">
                            <section className="w-[100%] md:w-[50%] ">
                                <p className="font-black text-[18px] text-[#93969C]">Name</p>
                            </section>
                            <section className="w-[100%] md:w-[50%]  mt-[20px] md:mt-0">
                                <p className="font-bold text-[18px] text-white md:text-end">Game'nGain</p>
                            </section>
                        </div>
                    </div>
                </section>
                <section className="w-[100%] md:w-[50%] flex flex-wrap items-center ">
                    <div className="w-[100%] flex md:justify-end flex-wrap">
                        <div className="w-[70%] ml-[40px] md:mr-[40px] flex flex-wrap mt-[32px] md:mt-0">
                            <section className="w-[100%] md:w-[50%] ">
                                <p className="font-black text-[18px] text-[#93969C]">Price, presale</p>
                            </section>
                            <section className="w-[100%] md:w-[50%]  mt-[20px] md:mt-0">
                                <p className="font-bold text-[18px] text-white md:text-end">0.0005355</p>
                            </section>
                        </div>
                        <div className="w-[70%]  ml-[40px] md:mr-[40px] flex mt-[24px] flex-wrap">
                            <section className="w-[100%] md:w-[50%] ">
                                <p className="font-black text-[18px] text-[#93969C]">Price, public sale</p>
                            </section>
                            <section className="w-[100%] md:w-[50%]  mt-[20px] md:mt-0 mb-[32px] md:mb-0">
                                <p className="font-bold text-[18px] text-white md:text-end">0.000765</p>
                            </section>
                        </div>
                        <div className="w-[70%]  ml-[40px] md:mr-[40px] flex mt-[24px] flex-wrap">
                            <section className="w-[100%] md:w-[50%] ">
                                <p className="font-black text-[18px] text-[#93969C]">Maximum supply</p>
                            </section>
                            <section className="w-[100%] md:w-[50%]  mt-[20px] md:mt-0 mb-[32px] md:mb-0">
                                <p className="font-bold text-[18px] text-white md:text-end">7,777,777,777</p>
                            </section>
                        </div>
                    </div>

                </section>
            </div>

        </div>
    )
}

export default TokenInfo