import React from "react";
import  ReactDOM  from "react-dom/client";
import Main from './App'
import './index.css'






const temp=ReactDOM.createRoot(document.getElementById('root'))
temp.render(<Main />)


