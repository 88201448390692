import React from "react";
import Slider from "react-slick";
import Space from '../assets/img/space.png'
import snakerider from '../assets/img/snakerider.png'
import snakeriderg from '../assets/img/games/snakerider.webp'
import gngchecker from '../assets/img/games/gng-checker.webp'
import backgammon from '../assets/img/games/backgammon.webp'
import captainhuntergngshooting from '../assets/img/games/captainhuntergngshooting.webp'
import gngmensch from '../assets/img/games/gngmensch.webp'
import gngsnakenladder from '../assets/img/games/gngsnakenladder.webp'
import gngtablefootball from '../assets/img/games/gngtablefootball.webp'
import gngtbiliard from '../assets/img/games/biliard.webp'
import gngslot from '../assets/img/games/slot.webp'
import pinball from '../assets/img/games/pinball.webp'

export default function SimpleSlider() {
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        responsive: [
            {
              breakpoint: 1300,
              settings: {
                slidesToShow: 3,
                slidesToScroll: 1,
                infinite: true,
                dots: true
              }
            },
            {
              breakpoint: 768,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
              }
            },
            {
              breakpoint: 500,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1
              }
            }
          ]
    };
    return (
        <>
        <div className="overflow-hidden cursor-pointer pl-[30px] xl:pl-[70px] bg-[black] pt-[130px] text-white relative pb-[70px]" id="games">
        <div className="fourthSecshadow1 rounded-[254px] w-[215px]  rotate-45 absolute top-[40%] lg:right-0 hidden md:flex"></div>
            <h2 className="font-bold text-[32px] md:text-[56px] text-white text-center md:text-start">Our Games</h2>
            <Slider {...settings} className="pt-[40px]">
                <div className="h-full">
                    <section className="w-[90%] h-full">
                        <figure className="w-full h-[50%]">
                            <img className="w-full h-full" src={snakeriderg} alt="GNG TOKEN Snakerider" />
                        </figure>
                        <div className="bg-[#0D0E12] h-[50%] pl-[14px] text-white">
                            <p className="font-bold text-[24px] pt-[14px]">Snakerider</p>
                            <p className="w-[95%] text-[14px] text-[#93969C] font-medium pt-2">Snakerider is an endless adventure in a colorful skin into a beautiful environment full of candies, delicious food, and even precids coins. offers an immersive and rewarding gaming experience. Enjoy a fun Play To Earn.</p>
                            <section className="flex pt-[24px]">
                                <div className="mr-[26px]"><i class="bi bi-star-fill pr-[8px] text-[#FFBD0C]"></i>10/10</div>
                                <div><i class="bi bi-exclamation-circle-fill text-[#93969C] pr-[8px]"></i>Token info GNG</div>
                            </section>
                            <button className="sliderBtn1 w-[95%] h-[40px] mt-[24px] mb-[14px]" onClick={() => {window.location.href="https://snakerider.io"}}>Published</button>
                        </div>
                    </section>
                </div>
                <div>
                    <section className="w-[90%] h-full">
                        <figure className="w-full h-[50%]">
                            <img className="w-full h-full" src={captainhuntergngshooting} alt="GNG TOKEN Capitan Hunter"  />
                        </figure>
                        <div className="bg-[#0D0E12] h-[50%] pl-[14px] text-white">
                            <p className="font-bold text-[24px] pt-[14px]">Captain Hunter</p>
                            <p className="w-[95%] text-[14px] text-[#93969C] font-medium pt-2">Captain Hunter (GNG Shooting) game, Become a sharpshooter, take down targets, and master the art of precision shooting. Join the action-packed multiplayer experience and showcase your skills.</p>
                            <section className="flex pt-[24px]">
                                <div className="mr-[26px]"><i class="bi bi-star-fill pr-[8px] text-[#FFBD0C]"></i>10/10</div>
                                <div><i class="bi bi-exclamation-circle-fill text-[#93969C] pr-[8px]"></i>Token info GNG</div>
                            </section>
                            <button className="sliderBtn3 w-[95%] h-[40px] mt-[24px]">Beta</button>
                        </div>
                    </section>
                </div>
                <div>
                    <section className="w-[90%] h-full">
                        <figure className="w-full h-[50%]">
                            <img className="w-full h-full" src={gngtablefootball} alt="GNG TOKEN Table Football"  />
                        </figure>
                        <div className="bg-[#0D0E12] h-[50%] pl-[14px] text-white">
                            <p className="font-bold text-[24px] pt-[14px]">Table Football</p>
                            <p className="w-[95%] text-[14px] text-[#93969C] font-medium pt-2">GNG Table Football game offers exciting virtual soccer matches. Compete with friends, control players, and score goals in multiplayer action. Play anytime, anywhere, and experience the thrill of this game!"</p>
                            <section className="flex pt-[24px]">
                                <div className="mr-[26px]"><i class="bi bi-star-fill pr-[8px] text-[#FFBD0C]"></i>10/10</div>
                                <div><i class="bi bi-exclamation-circle-fill text-[#93969C] pr-[8px]"></i>Token info GNG</div>
                            </section>
                            <button className="sliderBtn2 w-[95%] h-[40px] mt-[24px] mb-[14px]">In Development</button>
                        </div>
                    </section>
                </div>
                <div>
                    <section className="w-[90%] h-full">
                        <figure className="w-full h-[50%]">
                            <img className="w-full h-full" src={backgammon} alt="GNG TOKEN Backgammon"  />
                        </figure>
                        <div className="bg-[#0D0E12] h-[50%] pl-[14px] text-white">
                            <p className="font-bold text-[24px] pt-[14px]">Backgammon</p>
                            <p className="w-[95%] text-[14px] text-[#93969C] font-medium pt-2">GNG Backgammon online P2P game , Strategically move pieces, challenge friends, and experience multiplayer excitement.<br></br><br></br>Play anytime, anywhere and Earn!</p>
                            <section className="flex pt-[24px]">
                                <div className="mr-[26px]"><i class="bi bi-star-fill pr-[8px] text-[#FFBD0C]"></i>10/10</div>
                                <div><i class="bi bi-exclamation-circle-fill text-[#93969C] pr-[8px]"></i>Token info GNG</div>
                            </section>
                            <button className="sliderBtn2 w-[95%] h-[40px] mt-[24px] mb-[14px]">In Development</button>
                        </div>
                    </section>
                </div>
                <div>
                    <section className="w-[90%] h-full">
                        <figure className="w-full h-[50%]">
                            <img className="w-full h-full" src={gngchecker} alt="GNG TOKEN Checker"  />
                        </figure>
                        <div className="bg-[#0D0E12] h-[50%] pl-[14px] text-white">
                            <p className="font-bold text-[24px] pt-[14px]">Checker</p>
                            <p className="w-[95%] text-[14px] text-[#93969C] font-medium pt-2">Experience the thrill of GNG Checker online game. Engage in fast-paced battles, capture opponent's pieces, challenge friends. Enjoy multiplayer excitement anytime, anywhere and Earn!</p>
                            <section className="flex pt-[24px]">
                                <div className="mr-[26px]"><i class="bi bi-star-fill pr-[8px] text-[#FFBD0C]"></i>10/10</div>
                                <div><i class="bi bi-exclamation-circle-fill text-[#93969C] pr-[8px]"></i>Token info GNG</div>
                            </section>
                            <button className="sliderBtn2 w-[95%] h-[40px] mt-[24px] mb-[14px]">In Development</button>
                        </div>
                    </section>
                </div>
                <div>
                    <section className="w-[90%] h-full">
                        <figure className="w-full h-[50%]">
                            <img className="w-full h-full" src={gngsnakenladder} alt="GNG TOKEN Snake & Ladder"  />
                        </figure>
                        <div className="bg-[#0D0E12] h-[50%] pl-[14px] text-white">
                            <p className="font-bold text-[24px] pt-[14px]">Snake & Ladder</p>
                            <p className="w-[95%] text-[14px] text-[#93969C] font-medium pt-2">GNG Snake & Ladder online game: Roll the dice, climb ladders, and avoid snakes in this classic board game. Play with friends, enjoy multiplayer fun, and experience the excitement anytime, anywhere!</p>
                            <section className="flex pt-[24px]">
                                <div className="mr-[26px]"><i class="bi bi-star-fill pr-[8px] text-[#FFBD0C]"></i>10/10</div>
                                <div><i class="bi bi-exclamation-circle-fill text-[#93969C] pr-[8px]"></i>Token info GNG</div>
                            </section>
                            <button className="sliderBtn2 w-[95%] h-[40px] mt-[24px] mb-[14px]">In Development</button>
                        </div>
                    </section>
                </div>
                <div>
                    <section className="w-[90%] h-full">
                        <figure className="w-full h-[50%]">
                            <img className="w-full h-full" src={gngmensch} alt="GNG TOKEN Mensch" />
                        </figure>
                        <div className="bg-[#0D0E12] h-[50%] pl-[14px] text-white">
                            <p className="font-bold text-[24px] pt-[14px]">Mensch</p>
                            <p className="w-[95%] text-[14px] text-[#93969C] font-medium pt-2">GNG Mensch online game, Roll the dice, race your pieces, and outmaneuver opponents in this thrilling multiplayer experience. Play with friends, have fun, and enjoy the classic board game excitement & Earn!</p>
                            <section className="flex pt-[24px]">
                                <div className="mr-[26px]"><i class="bi bi-star-fill pr-[8px] text-[#FFBD0C]"></i>10/10</div>
                                <div><i class="bi bi-exclamation-circle-fill text-[#93969C] pr-[8px]"></i>Token info GNG</div>
                            </section>
                            <button className="sliderBtn2 w-[95%] h-[40px] mt-[24px] mb-[14px]">In Development</button>
                        </div>
                    </section>
                </div>
                <div>
                    <section className="w-[90%] h-full">
                        <figure className="w-full h-[50%]">
                            <img className="w-full h-full" src={gngtbiliard} alt="GNG TOKEN Pool"  />
                        </figure>
                        <div className="bg-[#0D0E12] h-[50%] pl-[14px] text-white">
                            <p className="font-bold text-[24px] pt-[14px]">Pool</p>
                            <p className="w-[95%] text-[14px] text-[#93969C] font-medium pt-2">Experience the thrill of GNG Pool, the ultimate  P2P & P2E  game. Stake your GNG tokens, compete against other players, and win big! Unlock rare NFTs, customize your pool table, and dominate the leaderboard.</p>
                            <section className="flex pt-[24px]">
                                <div className="mr-[26px]"><i class="bi bi-star-fill pr-[8px] text-[#FFBD0C]"></i>10/10</div>
                                <div><i class="bi bi-exclamation-circle-fill text-[#93969C] pr-[8px]"></i>Token info GNG</div>
                            </section>
                            <button className="sliderBtn2 w-[95%] h-[40px] mt-[24px] mb-[14px]">In Development</button>
                        </div>
                    </section>
                </div>
                <div>
                    <section className="w-[90%] h-full">
                        <figure className="w-full h-[50%]">
                            <img className="w-full h-full" src={gngslot} alt="GNG TOKEN Slot"  />
                        </figure>
                        <div className="bg-[#0D0E12] h-[50%] pl-[14px] text-white">
                            <p className="font-bold text-[24px] pt-[14px]">Slot</p>
                            <p className="w-[95%] text-[14px] text-[#93969C] font-medium pt-2">Experience GNG Slot, the thrilling P2E game. Spin, and earn GNG tokens. With stunning visuals and rewarding gameplay, GNG Slot is your gateway to excitement and lucrative rewards.</p>
                            <section className="flex pt-[24px]">
                                <div className="mr-[26px]"><i class="bi bi-star-fill pr-[8px] text-[#FFBD0C]"></i>10/10</div>
                                <div><i class="bi bi-exclamation-circle-fill text-[#93969C] pr-[8px]"></i>Token info GNG</div>
                            </section>
                            <button className="sliderBtn2 w-[95%] h-[40px] mt-[24px] mb-[14px]">In Development</button>
                        </div>
                    </section>
                </div>
                <div>
                    <section className="w-[90%] h-full">
                        <figure className="w-full h-[50%]">
                            <img className="w-full h-full" src={pinball} alt="GNG TOKEN Pinball"  />
                        </figure>
                        <div className="bg-[#0D0E12] h-[50%] pl-[14px] text-white">
                            <p className="font-bold text-[24px] pt-[14px]">Pin Ball</p>
                            <p className="w-[95%] text-[14px] text-[#93969C] font-medium pt-2">Dive into the world of GNG Pinball, the ultimate play-to-earn game. Score big, collect rare NFTs, and earn GNG tokens in this thrilling, nostalgic arcade experience, and dominate the leaderboard. Join the pinball</p>
                            <section className="flex pt-[24px]">
                                <div className="mr-[26px]"><i class="bi bi-star-fill pr-[8px] text-[#FFBD0C]"></i>10/10</div>
                                <div><i class="bi bi-exclamation-circle-fill text-[#93969C] pr-[8px]"></i>Token info GNG</div>
                            </section>
                            <button className="sliderBtn2 w-[95%] h-[40px] mt-[24px] mb-[14px]">In Development</button>
                        </div>
                    </section>
                </div>
            </Slider>
    
        </div>      
   </>

    );

}