import NFTpic from '../assets/img/nftpic.png'


const NFTgames = () => {
    return (
        <div className="NFTgame w-full flex justify-center bg-[black] pt-[64px] md:pt-[200px] flex-wrap">
            <figure className='relative w-full flex justify-center'>
                <div className="thirdtSecshadow1  hidden md:flex rounded-[254px]  w-[215px] md:w-[415px] absolute top-[40%] left-[-5%] md:left-[25%]"></div>
                <div className="thirdtSecshadow2  hidden md:flex rounded-[254px] w-[215px] md:w-[415px] absolute top-[60%] right-[-5%] md:right-[25%]"></div>
                <img className='relative z-30' src={NFTpic} alt='NFT' />
            </figure>
            <h3 className='w-[75%] md:w-[60%] font-bold text-[32px] md:text-[56px] text-white text-center'>Let's get into the fantasy world of exciting and <span className='gamengain-color font-bold text-[32px] md:text-[56px]'>revenue-generating</span> NFT games!</h3>

        </div>
    )
}


export default NFTgames