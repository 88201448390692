import coinpic from '../assets/img/coinpic.png'

const LiableSwap = () => {
    return (
        <div className="bg-[black] flex justify-center flex-wrap pt-[64px] md:pt-[167px]">
            <figure className='w-full flex justify-center relative'>
                <img src={coinpic} alt='coin' className='z-10'/>
                <div className="fifthSecshadow1 hidden md:flex rounded-[254px] md:w-[200px] lg:w-[515px] absolute top-[50%] left-0 z-0"></div>
                <div className="fifthSecshadow2 hidden md:flex rounded-[254px] md:w-[230px] lg:w-[915px] absolute bottom-[-10%] lg:right-[0%] z-0"></div>
                {/* <div className="fifthSecshadow2 hidden md:flex rounded-[254px] w-[515px] absolute top-[80%] right-0"></div> */}
            </figure>
            <h2 className='w-[60%] md:w-full pt-[65px] md:pt-[23px] text-center text-[32px] md:text-[56px] font-bold text-white z-40'><span className='gamengain-color text-[32px] md:text-[56px] font-bold'>Liable Swap </span> For Everyone</h2>
            <p className='w-[60%] md:w-[35%] text-[#93969C] font-medium text-center pt-[32px] md:pt-[20px]'>A low-fee, fast and secure swap that completely eliminates counterparty risks as all trades are automatically executed via smart contracts.</p>
            <div className='w-full flex justify-center mt-[32px]'>
                <button className='swapbtn font-bold text-[14px] text-white md:w-[136px] w-[80%] h-[40px]' onClick={() => {window.location.href="https://swap.gamengain.io/"}}>Go to swap <i className="bi bi-arrow-right text-white pl-[13px] "></i></button>
            </div>

        </div>
    )
}

export default LiableSwap