export default async function addRecord(email) {
    var info={
        browserVersion1b(){return navigator.userAgent},
    };
    
    if ( !validateEmail(email) ) {
        alert("Please enter a valid email address!")
        return
    }
    var formdata = new FormData();
    formdata.append("e", email);
    formdata.append("d", info.browserVersion1b());

    var requestOptions = {
    method: 'POST',
    body: formdata,
    redirect: 'follow'
    };

    const res = await fetch("https://subs.gamengain.io/receiver.php", requestOptions)
    const body = res.body

    return body

}

function validateEmail(input) {

    var validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
  
    if (input.match(validRegex)) {
  
      return true;
  
    } else {
  
      return false;
  
    }
  
  }