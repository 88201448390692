import firstSectionpic from '../assets/img/3Dillusrtration.webp'

const Welcome = () => {
    return (
        <div className="w-full flex flex-wrap justify-center bg-[black]">
            <section className="rectangle w-[90%] md:w-[60%] flex flex-wrap relative md:pl-[50px] lg:pl-[110px] ">
                <h1 className="w-[70%] xl:w-[100%] 2xl:w-[80%] text-white font-bold text-[32px] lg:text-[44px] xl:text-[64px] mt-[66px]">Welcome to <span className="gamengain-color font-bold text-[32px] lg:text-[44px] xl:text-[64px]">Game'nGain</span><br></br>A World of Fun and Earning</h1>
                <p className="w-[90%] md:w-[80%] lg:w-[75%] xl:w-[60%] text-[#93969C] font-medium pt-[16px]">Game'nGain (GNG) is a digital asset based on Polygon network that provides P2E games, Swap and an exclusive NFT Marketplace that lets users buy, sell and trade NFTs there.</p>
                <div className="w-full mt-[20px]">
                    <a href='https://swap.gamengain.io/#/swap'><button className="buyGNG-btn w-[108px] h-[40px] text-white text-[14px] font-bold md:mb-[90px] xl:mb-6">Buy GNG</button></a>
                </div>
                <div className="firstSecshadow1 hidden md:flex rounded-[254px] absolute bottom-[20%] left-[20%] -z-10"></div>
                <div className="firstSecshadow2 hidden md:flex rounded-[254px] absolute bottom-[5%] left-0 -z-10"></div>
            </section>
            <section className="w-[90%] md:w-[40%] mt-[23px] flex md:items-center relative ">
                <img src={firstSectionpic} className='relative z-10  xl:w-[90%]' alt='GNG TOKEN'/>
                <div className="firstSecshadow3 rounded-[254px] absolute bottom-[50%] right-[50%]"></div>
                <div className="firstSecshadow4 rounded-[254px] absolute bottom-[10%] right-[10%]"></div>
            </section>
            <div className='w-full h-[48px] bg-[#00F219] 2xl:mt-4'>
                <div className=' flex  items-center h-full overflow-hidden'>
                    <section className='w-full discordMove flex items-center '>
                        <i className="text-[15px] md:text-[20px] bi bi-discord pr-[10px] md:pr-[16px]  md:pl-[44px]"></i>
                        <p className='w-full font-bold text-[15px]  lg:text-[20px] xl:text-[24px]'>Join Our Discord Community</p>
                    </section>
                    <section className='w-full discordMove flex items-center  '>
                        <i className="text-[15px] md:text-[20px] bi bi-discord pr-[10px] md:pr-[16px]  md:pl-[44px]"></i>
                        <p className='w-full font-bold text-[15px]  lg:text-[20px] xl:text-[24px]'>Join Our Discord Community</p>
                    </section>
                    <section className='w-full discordMove flex items-center  hidden md:flex'>
                        <i className="text-[15px] md:text-[20px] bi bi-discord pr-[16px] pl-[27px] md:pl-[44px]"></i>
                        <p className='w-full font-bold text-[15px] lg:text-[20px] xl:text-[24px]'>Join Our Discord Community</p>
                    </section>
                </div>
            </div>
        </div>
    )
}


export default Welcome