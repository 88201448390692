import Icon1 from '../assets/img/Icon1.svg'
import Icon2 from '../assets/img/Icon2.svg'
import Icon3 from '../assets/img/Icon3.svg'
import Icon4 from '../assets/img/Icon4.svg'


const Advantages = () => {
    return (
        <div className="w-full flex justify-center bg-[black] pt-[64px] md:pt-[195px] relative">
            <div className="secondtSecshadow1  hidden md:flex w-[240px] md:w-[273px] lg:w-[673px] rounded-[254px] absolute top-[5%] md:top-[20%] left-[5%]"></div>
            {/* <div className="secondtSecshadow1 rounded-[254px] absolute top-[5%] md:top-[20%] left-[35%]"></div> */}
            <div className="secondtSecshadow2 hidden md:flex w-[240px] md:w-[273px] lg:w-[673px] rounded-[254px] absolute top-[10%] md:top-[35%] lg:right-0"></div>
            {/* <div className="secondtSecshadow2 rounded-[254px] absolute top-[5%] md:top-[20%] right-[35%]"></div> */}
            <section className="w-[80%]  flex justify-center flex-wrap relative z-20">
                <h2 className="text-center text-white text-[32px] md:text-[56px] font-bold">The Advantages of GNG Token</h2>
                <div className="w-full  min-h-[50px] pt-[44px] md:pt-[93px] px-6 flex flex-wrap">
                    <section className="w-[100%] md:w-[25%]  flex justify-center flex-wrap">
                        <figure className='relative'>
                            <img src={Icon1} alt='GNG TOKEN Ecosystem'/>
                            <div className="secondtSecshadow3 rounded-[254px] absolute top-[50%] right-[50%] hidden md:flex"></div>
                        </figure>
                        <h2 className='w-full text-center font-bold text-white text-[24px] pt-3'>Evolving Ecosystem</h2>
                        <p className='w-[90%] text-center text-[14px] font-medium text-[#93969C] pt-[12px]'>A complete financial ecosystem along various games in different genres.</p>
                    </section>
                    <section className="w-[100%] md:w-[25%]  flex justify-center flex-wrap pt-[64px] md:pt-0">
                        <figure className='relative'>
                            <img src={Icon2} alt='GNG TOKEN P2E Games'/>
                            <div className="secondtSecshadow4 rounded-[254px] absolute top-[50%] right-[50%] hidden md:flex"></div>
                        </figure>
                        <h2 className='w-full text-center font-bold text-white text-[24px] pt-3'>Engaging P2E Games</h2>
                        <p className='w-[90%] text-center text-[14px] font-medium text-[#93969C] pt-[12px]'>Find the most trending NFT games here and start playing to earn GNG tokens!</p>
                    </section>
                    <section className="w-[100%] md:w-[25%]  flex justify-center flex-wrap pt-[64px] md:pt-0">
                    <figure className='relative'>
                            <img src={Icon3} alt='GNG TOKEN NFT MARKET'/>
                            <div className="secondtSecshadow5 rounded-[254px] absolute top-[50%] right-[50%] hidden md:flex"></div>
                        </figure>
                        <h2 className='w-full text-center font-bold text-white text-[24px] pt-3'>Exclusive NFT Market</h2>
                        <p className='w-[90%] text-center text-[14px] font-medium text-[#93969C] pt-[12px]'>We offer an outstanding NFT marketplace to buy or sell all NFT game assets.</p>
                    </section>
                    <section className="w-[100%] md:w-[25%] flex justify-center flex-wrap pt-[64px] md:pt-0">
                    <figure className='relative'>
                            <img src={Icon4} alt='GNG TOKEN SAFE'/>
                            <div className="secondtSecshadow6 rounded-[254px] absolute top-[50%] right-[50%] hidden md:flex"></div>
                        </figure>
                        <h2 className='w-full text-center font-bold text-white text-[24px] pt-3'>Safe & Secure</h2>
                        <p className='w-[90%] text-center text-[14px] font-medium text-[#93969C] pt-[12px]'>Security is crucial in the GameFi world. We make sure our products follow the latest security protocols.</p>
                    </section>
                </div>
            </section>
        </div>
    )
}
export default Advantages