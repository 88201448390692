import logo from '../assets/img/logo.png'

const Footer = () => {
    return (
        <footer className="w-full bg-[black] flex justify-center ">
            <section className="foot w-[80%]  flex flex-wrap mt-[123px] md:mt-[238px] text-white">
                <div className="w-[100%] md:w-[20%]  flex md:justify-center ">
                    <img className='w-[73px] h-[73px]' src={logo} alt="GNG TOKEN" />
                </div>
                <div className="w-[100%] md:w-[80%]  flex flex-wrap ">
                    <section className='w-1/2 md:w-[25%] '>
                        <ul>
                            <li className='text-[20px] font-bold pb-[20px] pt-[40px] md:pt-0'>Develop</li>
                            <li>Docs</li>
                            <li className='pt-[14px]'><a href='https://github.com/GNGToken'>Github</a></li>
                            <li className='pt-[14px]'>Developer</li>
                        </ul>
                    </section>
                    <section className='w-1/2 md:w-[25%] '>
                        <ul>
                            <li className='text-[20px] font-bold pb-[20px] pt-[40px] md:pt-0'>Contact Us</li>
                            <li><a href='https://t.me/GNGtoken'>Telegram</a></li>
                            <li className='pt-[14px]'><a href='https://instagram.com/gngtoken'>Instagram</a></li>
                            <li className='pt-[14px]'><a href='https://medium.com/@gamengain.io'>Medium</a></li>
                        </ul>
                    </section>
                    <section className='w-1/2 md:w-[25%] '>
                        <ul>
                            <li className='text-[20px] font-bold pb-[20px] pt-[32px] md:pt-0'>Other</li>
                            <li>Privacy policy</li>
                            <li className='pt-[14px]'>Terms of Use</li>
                            <li className='pt-[14px]'>Litepaper</li>
                        </ul>
                    </section>
                    <section className='w-1/2 md:w-[25%] '>
                        <ul>
                            <li className='text-[20px] font-bold pb-[20px] pt-[32px] md:pt-0'>Our games</li>
                            <li><a href='https://snakerider.io'>Snakerider</a></li>
                            <li className='pt-[14px]'>Captain Hunter</li>
                            <li className='pt-[14px]'>Coming soon</li>
                        </ul>
                    </section>
                </div>
                <div className="w-[100%]  font-medium text-center pt-[36px]  pb-2 relative z-50 ">
                <section className=' min-h-[20px] flex flex-wrap justify-center  mb-5 space-x-4'>
                        <a href='https://discord.gg/pMTWFcHz'><i className="bi bi-discord  md-[pl-20px] lg:pl-[32px] text-[16px] md:text-[19px] lg:text-[24px] "></i></a>
                        <a href='https://twitter.com/GNGToken'><i className="bi bi-twitter pl-0 md:pl-[20px] lg:pl-[32px] twitter-color text-[16px] md:text-[19px] lg:text-[24px]"></i></a>
                        <a href='https://instagram.com/gngtoken'><i className="bi bi-instagram pl-0 md:pl-[20px] lg:pl-[32px] text-[16px] md:text-[19px] lg:text-[24px]"></i></a>
                        <a href='https://t.me/GNGtoken'><i className="bi bi-telegram pl-0 md:pl-[20px] lg:pl-[32px] text-[16px] md:text-[19px] lg:text-[24px]"></i></a>
                        <a href='https://www.facebook.com/GNGToken'><i className="bi bi-facebook pl-0 md:pl-[20px] lg:pl-[32px] text-[16px] md:text-[19px] lg:text-[24px]"></i></a>
                        <a href='https://medium.com/@gamengain.io'><i className="bi bi-medium pl-0 md:pl-[20px] lg:pl-[32px] text-[16px] md:text-[19px] lg:text-[24px]"></i></a>
                        <a href='https://github.com/GNGToken'><i className="bi bi-github pl-0 md:pl-[20px] lg:pl-[32px] text-[16px] md:text-[19px] lg:text-[24px]"></i></a>
                        <a href='https://www.youtube.com/channel/UC4u3Mx4TjT_7RhUjbsTLy8w'><i className="bi bi-youtube pl-0 md:pl-[20px] lg:pl-[32px] text-[16px] md:text-[19px] lg:text-[24px]"></i></a>
                    </section>
                    <p>© 2023 Gamengain. All rights reserved</p>
                    <div className='footerShadow1 w-[162px] md:w-[474px] rounded-full absolute top-[-20%] md:top-[90%] left-[0] md:left-[10%]'></div>
                    <div className='footerShadow2 w-[162px] md:w-[474px] rounded-full absolute top-[-20%] md:top-[90%] right-[0] md:right-[10%]'></div>
                </div>
            </section>
        </footer>
    )
}

export default Footer