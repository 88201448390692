

const Roadmap = () => {

    const data = [
        {
            q1: "Q1",
            y1: "2022",
            isActive: true,
            content: <><p className="w-[100%] text-[14px] md:text-[16px] text-center text-[#696A6F] font-medium mt-[15px]">- Ideation</p>
            <p className="w-[95%] text-[14px] md:text-[16px] text-center text-[#696A6F] font-medium">- Core Team Foundation</p>
            <p className="w-[100%] text-[14px] md:text-[16px] text-center text-[#696A6F] font-medium"> - Planning for the GNG Structure & Ecosystem</p></>
        },
        {
            q1: "Q2",
            y1: "2022",
            isActive: true,
            content: <><p className="w-[100%] text-[14px] md:text-[16px] text-center text-[#696A6F] font-medium mt-[15px]">- Market & Competitive Analysis & Research</p>
            <p className="w-[95%] text-[14px] md:text-[16px] text-center text-[#696A6F] font-medium">- Determining Investors & Project Funding for GNG</p>
            <p className="w-[100%] text-[14px] md:text-[16px] text-center text-[#696A6F] font-medium"> - Starting development of SnakeRider</p></>
        },
        {
            q1: "Q3",
            y1: "2022",
            isActive: true,
            content: <><p className="w-[100%] text-[14px] md:text-[16px] text-center text-[#696A6F] font-medium mt-[15px]">- GNG NFT Marketplace Development</p>
            <p className="w-[95%] text-[14px] md:text-[16px] text-center text-[#696A6F] font-medium">- Launching The SnakeRider (Test Ver.)</p></>
        },{
            q1: "Q4",
            y1: "2022",
            isActive: true,
            content: <><p className="w-[100%] text-[14px] md:text-[16px] text-center text-[#696A6F] font-medium mt-[15px]">- Finalizing The GNG Ecosystem & Tokenomics</p>
            <p className="w-[95%] text-[14px] md:text-[16px] text-center text-[#696A6F] font-medium">- Starting The GNG Website Development</p>
            <p className="w-[100%] text-[14px] md:text-[16px] text-center text-[#696A6F] font-medium"> - Starting Marketing Development & Community Building</p></>
        },{
            q1: "Q1",
            y1: "2023",
            isActive: true,
            content: <><p className="w-[100%] text-[14px] md:text-[16px] text-center text-[#696A6F] font-medium mt-[15px]">- Starting The GNG Swap Development</p>
            <p className="w-[95%] text-[14px] md:text-[16px] text-center text-[#696A6F] font-medium">- Creating Game NFTs</p>
            <p className="w-[100%] text-[14px] md:text-[16px] text-center text-[#696A6F] font-medium"> - Launching GNG Official Website</p>
            <p className="w-[100%] text-[14px] md:text-[16px] text-center text-[#696A6F] font-medium">- GNG Lighpaper Release</p></>
        },{
            q1: "Q2",
            y1: "2023",
            isActive: true,
            content: <><p className="w-[100%] text-[14px] md:text-[16px] text-center text-[#696A6F] font-medium mt-[15px]">- Branding and Visual Identity Development</p>
            <p className="w-[95%] text-[14px] md:text-[16px] text-center text-[#696A6F] font-medium">- Social Media Development</p>
            <p className="w-[100%] text-[14px] md:text-[16px] text-center text-[#696A6F] font-medium"> - SnakeRider Official Launch (Free Version)</p>
            <p className="w-[100%] text-[14px] md:text-[16px] text-center text-[#696A6F] font-medium">- Token & Smart-contract Development & Optimization (Testnet)</p></>
        },{
            q1: "Q3",
            y1: "2023",
            isActive: false,
            content: <><p className="w-[100%] text-[14px] md:text-[16px] text-center text-[#696A6F] font-medium mt-[15px]">- Token Creation & Running  Audit</p>
            <p className="w-[95%] text-[14px] md:text-[16px] text-center text-[#696A6F] font-medium">- Strategic Partnership and Co-Marketing</p>
            <p className="w-[100%] text-[14px] md:text-[16px] text-center text-[#696A6F] font-medium"> - IDO</p>
            <p className="w-[100%] text-[14px] md:text-[16px] text-center text-[#696A6F] font-medium">- Influencer collaborations</p>
            <p className="w-[100%] text-[14px] md:text-[16px] text-center text-[#696A6F] font-medium">- Listing on UniSwap</p>
            <p className="w-[100%] text-[14px] md:text-[16px] text-center text-[#696A6F] font-medium">- CoinMarketCap & CoinGecko Listing</p>
            <p className="w-[100%] text-[14px] md:text-[16px] text-center text-[#696A6F] font-medium">- Wallet Listing</p></>
        },{
            q1: "Q4",
            y1: "2023",
            isActive: false,
            content: <><p className="w-[100%] text-[14px] md:text-[16px] text-center text-[#696A6F] font-medium mt-[15px]">- Launching The NFT Marketplace</p>
            <p className="w-[95%] text-[14px] md:text-[16px] text-center text-[#696A6F] font-medium">- SnakeRider Official Launch (P2E Version)</p>
            <p className="w-[100%] text-[14px] md:text-[16px] text-center text-[#696A6F] font-medium"> - Exchange Listing</p>
            <p className="w-[100%] text-[14px] md:text-[16px] text-center text-[#696A6F] font-medium">- Community Growing</p>
            <p className="w-[100%] text-[14px] md:text-[16px] text-center text-[#696A6F] font-medium">- Launching The Beta Ver. of 2nd & 3rd Games</p></>
        },{
            q1: "Q1",
            y1: "2024",
            isActive: false,
            content: <><p className="w-[100%] text-[14px] md:text-[16px] text-center text-[#696A6F] font-medium mt-[15px]">- Launching The Full Ver. of the 2nd Game</p>
            <p className="w-[95%] text-[14px] md:text-[16px] text-center text-[#696A6F] font-medium">- Exclusive Swap Release</p>
            <p className="w-[100%] text-[14px] md:text-[16px] text-center text-[#696A6F] font-medium"> - Incentivize Marketing Campaign</p></>
        },{
            q1: "Q2",
            y1: "2024",
            isActive: false,
            content: <><p className="w-[100%] text-[14px] md:text-[16px] text-center text-[#696A6F] font-medium mt-[15px]">- Launching Beta Ver. of 4th & 5th Games</p>
            <p className="w-[95%] text-[14px] md:text-[16px] text-center text-[#696A6F] font-medium">- Major Update on All Platforms</p>
            <p className="w-[100%] text-[14px] md:text-[16px] text-center text-[#696A6F] font-medium"> - Second Exchange Listing</p></>
        },{
            q1: "Q3",
            y1: "2024",
            isActive: false,
            content: <><p className="w-[100%] text-[14px] md:text-[16px] text-center text-[#696A6F] font-medium mt-[15px]">- Launching The Full Ver. of 3rd Game</p>
            <p className="w-[95%] text-[14px] md:text-[16px] text-center text-[#696A6F] font-medium">- Official Launch of GNG Payment Gateway</p></>
        },{
            q1: "Q4",
            y1: "2024",
            isActive: false,
            content: <><p className="w-[100%] text-[14px] md:text-[16px] text-center text-[#696A6F] font-medium mt-[15px]">- Launching Full Ver. of 4th Game</p>
            <p className="w-[95%] text-[14px] md:text-[16px] text-center text-[#696A6F] font-medium">- GNG Roadmap Update </p>
            <p className="w-[100%] text-[14px] md:text-[16px] text-center text-[#696A6F] font-medium"> - Major Marketing Expansion in Game Industry</p></>
        }
    ]
    return (
        <div className="w-full bg-[black] flex flex-wrap justify-center pt-[65px] md:pt-[128px] ">
            <h3 className="text-[32px] md:text-[56px] text-white font-bold text-center">Roadmap</h3>
            <section className="w-full overflow-x-scroll lg:flex justify-center flex-wrap ">
                <section
                    class="mt-[43px] md:mt-[80px] min-h-[50px] ml-[50px] xl:ml-[10%] 2xl:ml-0 flex  items-center relative w-[1000px] xl:w-[1272px]">
                    <div class="w-[950px] xl:w-[2984px] h-[5.5px] bg-[#0053F4] opacity-30 relative">
                        <div class="eightSecshadow rounded-[254px] w-[100%] absolute "></div>
                    </div>
                    <div class="w-[1705px] xl:w-[1879px] h-[5px] rounded-[5.5px] bg-[#0053F4] absolute"></div>
                    
                    <section
                        class="w-[32px] h-[32px] rounded-full bg-[#02318B] flex justify-center items-center absolute top-[9px] left-[150px] xl:left-[214px]">
                        <div class="w-[12px] h-[12px] rounded-full bg-[#0053F4]"></div>
                    </section>
                    <section
                        class="w-[32px] h-[32px] rounded-full bg-[#02318B] flex justify-center items-center absolute top-[9px] left-[489px] xl:left-[545px]">
                        <div class="w-[12px] h-[12px] rounded-full bg-[#0053F4]"></div>
                    </section>
                    <section
                        class="w-[32px] h-[32px] rounded-full bg-[#02318B] flex justify-center items-center absolute top-[9px] left-[786px] xl:left-[870px]">
                        <div class="w-[12px] h-[12px] rounded-full bg-[#0053F4]"></div>
                    </section>
                    <section
                        class="w-[32px] h-[32px] rounded-full bg-[#02318B] flex justify-center items-center absolute top-[9px] left-[1086px] xl:left-[1200px]">
                        <div class="w-[12px] h-[12px] rounded-full bg-[#0053F4]"></div>
                    </section>
                    <section
                        class="w-[32px] h-[32px] rounded-full bg-[#02318B] flex justify-center items-center absolute top-[9px] left-[1384px] xl:left-[1527px]">
                        <div class="w-[12px] h-[12px] rounded-full bg-[#0053F4]"></div>
                    </section>
                    <section
                        class="w-[32px] h-[32px] rounded-full bg-[#02318B] flex justify-center items-center absolute top-[9px] left-[1686px] xl:left-[1854px]">
                        <div class="w-[12px] h-[12px] rounded-full bg-[#0053F4]"></div>
                    </section>
                    <section
                        class="w-[32px] h-[32px] rounded-full bg-[#02318B] flex justify-center items-center absolute top-[9px] left-[1985px] xl:left-[2180px]">
                        <div class="w-[12px] h-[12px] rounded-full bg-[#0053F4]"></div>
                    </section>
                    <section
                        class="w-[32px] h-[32px] rounded-full bg-[#02318B] flex justify-center items-center absolute top-[9px] left-[2293px] xl:left-[2510px]">
                        <div class="w-[12px] h-[12px] rounded-full bg-[#0053F4]"></div>
                    </section>
                    <section
                        class="w-[32px] h-[32px] rounded-full bg-[#02318B] flex justify-center items-center absolute top-[9px] left-[2558px] xl:left-[2836px]">
                        <div class="w-[12px] h-[12px] rounded-full bg-[#0053F4]"></div>
                    </section>
                    <section
                        class="w-[32px] h-[32px] rounded-full bg-[#02318B] flex justify-center items-center absolute top-[9px] left-[2885px] xl:left-[3160px]">
                        <div class="w-[12px] h-[12px] rounded-full bg-[#0053F4]"></div>
                    </section>
                    <section
                        class="w-[32px] h-[32px] rounded-full bg-[#02318B] flex justify-center items-center absolute top-[9px] left-[3186px] xl:left-[3489px]">
                        <div class="w-[12px] h-[12px] rounded-full bg-[#0053F4]"></div>
                    </section>
                    <section
                        class="w-[32px] h-[32px] rounded-full bg-[#02318B] flex justify-center items-center absolute top-[9px] left-[3485px] xl:left-[3817px]">
                        <div class="w-[12px] h-[12px] rounded-full bg-[#0053F4]"></div>
                    </section>
                </section>
                <section className="ml-[50px] xl:ml-[10%] 2xl:ml-0 flex  items-center relative w-[1000px] xl:w-[1272px]  ">
                    {data.map( (row, index) => {
                        return <div className={`w-[20%] flex flex-wrap  ml-[10%] min-w-[200px] mb-auto mt-5 ${(!row.isActive) ? `opacity-50` : ``}`} key={index}>
                            <p className="w-full text-[#00F219] text-[27px] md:text-[32px] font-black text-center"><span className="text-[#0053F4] text-[27px] md:text-[32px] font-black">{row.q1} </span>{row.y1}</p>
                            {row.content}
                        </div>
                    })}
                    
                    
                </section>



            </section>


        </div>
    )
}

export default Roadmap