import unity from '../assets/img/unity.png'
import unreal from '../assets/img/unreal.png'
import unicorn from '../assets/img/unicorn.png'
import polygon from '../assets/img/polygon.png'
import React from "react";
import Slider from "react-slick";
// import 

const Partners = () => {
    return (
        <div className="w-full bg-[black] flex justify-center ">
            <section className="w-[80%] mt-[66px] md:mt-[128px] relative hidden xl:flex flex-wrap ">
                <h2 className="w-full font-bold text-white text-[32px] md:text-[56px] text-center">Partners</h2>
                <div className='w-full flex items-center mt-[60px] z-50  justify-center'>
                    <img className='ml-[3%]' src={unity} alt='unity' />
                    <img className='ml-[118px]' src={unreal} alt='unreal'/>
                    <img className='ml-[118px]' src={unicorn} alt='unicorn'/>
                    <img className='ml-[5%]' src={polygon} alt='polygon'/>
                </div>
                <div className='ninthSecshadow1 w-[603px] absolute top-[60%] left-0'></div>
                <div className='ninthSecshadow2 w-[603px] absolute top-[50%] right-0'></div>
            </section>
            <section className='w-[80%] mt-[66px] md:mt-[128px] flex flex-wrap xl:hidden relative'>
                <Partnersslide />
                <div className='ninthSecshadow3 w-[160px] absolute top-[70%] left-0'></div>
                <div className='ninthSecshadow4 w-[160px] absolute top-[60%] right-0'></div>
            </section>
        </div>
    )
}


function Partnersslide() {
    var settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1
    };
    return (
        <>
            <div className="overflow-hidden cursor-pointer bg-[black] text-white flex justify-center">
                <section className="w-[100%] mt-[25px]">
                    <h2 className="font-bold text-[32px] text-white text-center pb-[65px]">Partners</h2>
                    <div className='z-50'>
                        <Slider {...settings}>
                            <div className='border border-black mt-[35px] sm:mt-[90px] ml-5 outline-0'>
                                <img className='w-[86px] md:w-[176px] h-[32px] md:h-[64px]' src={unity} alt='unity'/>
                            </div>
                            <div className='ml-[20px] mt-[25px] sm:mt-[70px] outline-0'>
                                <img className='w-[66px] md:w-[135px] h-[69px] md:h-[141px]' src={unreal} alt='unreal'/>
                            </div>
                            <div className='outline-0'>
                                <img src={unicorn} alt='unicorn'/>
                            </div>
                            <div className='outline-0'>
                                <img src={polygon} alt='polygon'/>
                            </div>
                        </Slider>
                    </div>
                </section>

            </div>

        </>
    );
}





export default Partners