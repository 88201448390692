import logo from '../assets/img/logo.png'

const Menu = () => {
    function OpenMenu() {
        document.getElementById('Ham').style.transform = 'translateX(0)'
    }
    return (
        <header className="w-full">
            <nav className="w-full flex bg-[black] min-h-[50px] relative pt-3">
                <HamMenu />
                <section className="w-[50%] md:w-[30%]  flex md:justify-center items-center">
                    <div className='w-[15%] h-[100%] flex justify-center items-center md:hidden mx-[26px] cursor-pointer '>
                        <section onClick={OpenMenu}>
                            <div className='w-[100%] h-[7px] flex justify-end '>
                                <span className='w-[11px] h-[2.5px] bg-[white] block rounded'></span>
                            </div>
                            <div className='w-[100%] h-[7px] flex justify-center'>
                                <span className='w-[20px] h-[2.5px] bg-[white] block rounded'></span>
                            </div>
                            <div className='w-[100%] h-[7px] flex justify-start'>
                                <span className='w-[11px] h-[2.5px] bg-[white] block rounded'></span>
                            </div>
                        </section>
                    </div>
                    <img className='w-[35px] h-[35px] md:w-[73px] md:h-[73px]' src={logo} alt="GNG TOKEN" />
                </section>
                <section className="hidden md:flex md:w-[40%]  text-white">
                    <ul className='w-full flex justify-center items-center '>
                        <li className='pr-[48px] text-[#01EF1E] relative cursor-pointer'>Swap
                            <span className='w-[4px] h-[4px] rounded-full bg-[#01EF1E] block ml-[16px] absolute top-[30px]'></span></li>
                        <li className='pr-[48px] cursor-pointer'><a href='#games'>Games</a></li>
                        <li className='pr-[48px] cursor-pointer'><a href='https://nft.gamengain.io/'>Marketplace</a></li>
                        <li className='pr-[48px] cursor-pointer'>Roadmap</li>
                        <li className='pr-[48px] cursor-pointer'>Litepaper</li>
                    </ul>
                </section>
                <section className="w-[50%] md:w-[30%]  text-white flex items-center justify-center md:justify-start">
                    <div className='btn-border w-[65%] md:w-[75%] lg:w-[65%] h-[60px] flex justify-center items-center ml-3 lg:ml-0 hidden md:flex'>
                        <section className='w-[99%] h-[98%] bg-[black] flex items-center justify-center '>
                            <a href='https://swap.gamengain.io/#/swap'><button className='w-[92px] h-[40px] bg-[#20232C] text-[14px] mr-[16px]'>Buy GNG</button></a>
                            <button className='connectWallet-btn w-[55%] h-[40px] text-[14px]'>Connect Wallet</button>
                        </section>
                    </div>
                    <button className='flex justify-center items-center md:hidden connectWallet-btn w-[75%] h-[40px] text-[14px]'>Connect Wallet</button>
                </section>
            </nav>
        </header>
    )
}

function HamMenu() {
    function CloseMenu(){
        document.getElementById('Ham').style.transform='translateX(-100%)'
    }
    return (
        <div id='Ham' className='w-full h-full bg-[black] opacity-90 fixed z-50 translate-x-[-100%] duration-700 md:hidden'>
            <div className='w-full h-[40%] flex justify-center items-end pb-5 relative'>
                <img src={logo} alt='GNG TOKEN'/>
                <span onClick={CloseMenu} className='absolute top-[20px] left-[25px] cursor-pointer'><i className="bi-x-lg text-white text-[24px]"></i></span>
            </div>
            <div className='w-full h-[60%] flex justify-center text-white'>
                <ul>
                    <li className='cursor-pointer text-[30px] font-bold text-center my-4'>Swap</li>
                    <li className='cursor-pointer text-[30px] font-bold text-center my-4'>Games</li>
                    <li className='cursor-pointer text-[30px] font-bold text-center my-4'>Roadmap</li>
                    <li className='cursor-pointer text-[30px] font-bold text-center my-4'>Litepaper</li>
                </ul>

            </div>
        </div>
    )
}


export default Menu
